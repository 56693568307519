import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, Link } from 'gatsby-plugin-intl'
import Language from './Language'

const Header = props => (
  <header id="header" className="alt">
    {/* <Link to="/" className="logo">
      <strong>300SL Gullwing-Roadster </strong>{' '}
      <span>Club&nbsp;Belgium&nbsp;vzw</span>
    </Link> */}
    <nav id="navbar" class="navbar">
      <ul className="language-link">
        <Language />
      </ul>
      {/* <a className="menu-link" onClick={props.onToggleMenu} href="#menu">
        Menu
      </a> */}
    </nav>
  </header>
)

Header.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default injectIntl(Header)
