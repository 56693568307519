import React from 'react'
import { changeLocale, IntlContextConsumer } from 'gatsby-plugin-intl'

const languages = {
  en: { name: 'EN', icon: 'gb' },
  nl: { name: 'NL', icon: 'nl' },
  fr: { name: 'FR', icon: 'fr' },
}

const Language = () => {
  return (
    <IntlContextConsumer>
      {intl => (
        <li class="dropdown">
          <a href="#">
            <span
              className={`flag-icon flag-icon-${languages[intl.language].icon}`}
            ></span>{' '}
            {languages[intl.language].name}
            <span className="caret"></span>
          </a>
          <ul>
            {intl.languages
              .filter(lang => lang !== intl.language)
              .map(language => (
                <li>
                  <a
                    key={language}
                    onClick={() => changeLocale(language)}
                    href="#home"
                  >
                    <span
                      className={`flag-icon flag-icon-${languages[language].icon}`}
                    ></span>{' '}
                    {languages[language].name}
                  </a>
                </li>
              ))}
          </ul>
        </li>
      )}
    </IntlContextConsumer>
  )
}

export default Language
